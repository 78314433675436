import styled, { css } from "styled-components";

import {
  H0,
  H0_LAPTOP,
  TEXT_BODY_2,
} from "../../../../../../assets/styles/typography";
import ReactTypingEffect from "../../../../../../../vendor/react-typing-effect/src/lib";
import { device } from "../../../../../../utils/breakpoints";
import {
  fadeIn,
  fadeOut,
  slideFromTop,
} from "../../../../../../utils/animations";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 88px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  background: #ffffff;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${slideFromTop} 300ms ease-out
        `};
  animation-fill-mode: forwards;

  @media ${device.computer} {
    padding: 112px 216px 80px 120px;
    height: auto;
  }

  @media ${device.desktop} {
    padding: 128px 120px 120px 120px;
    height: 100vh;
  } ;
`;
interface TypingEffectProps {
  slide?: number;
}

export const StyledTypingEffect = styled(ReactTypingEffect)`
  display: block;
  color: #013cff;
  height: 180px;
  margin-top: 32px;
  margin-bottom: 80px;
  font-size: 194px;
  line-height: 160px;
  font-weight: 800;
  z-index: -1;
  white-space: nowrap;
  transform: ${(props: TypingEffectProps) =>
    props.slide ? `translate3d(${props.slide * -10}rem, 0, 0)` : "none"};

  span {
    font-weight: initial;
    font-size: 162px;
    transform: translateY(-20px);
  }

  @media ${device.tabletLandscape} {
    margin-bottom: 40px;
  }

  @media ${device.laptop} {
    ${H0_LAPTOP}
    height: 300px;
    margin-bottom: 0;
    margin-top: 0;

    span {
      font-weight: initial;
      font-size: 232px;
      transform: translateY(-32px);
    }
  }

  @media ${device.laptopL} {
    span {
      font-weight: initial;
      font-size: 300px;
      transform: translateY(-32px);
    }
  }

  @media ${device.desktop} {
    ${H0}
    height: 400px;

    span {
      font-weight: initial;
      font-size: 360px;
      transform: translateY(-52px);
    }
  } ;
`;

export const DescriptionWrapper = styled.div`
  width: 100%;
  padding-right: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #151515;
  white-space: pre-line;

  @media ${device.tabletLandscape}, ${device.computer} {
    width: 90%;
    padding-right: 48px;
    flex-direction: row;
  }

  @media ${device.computer} {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(11, 70px);
    grid-column-gap: 32px;
    padding: 80px 0 0 0;
  }

  @media ${device.desktop} {
    grid-template-columns: repeat(12, 110px);
    padding: 80px 0 0 0;
  } ;
`;

export const Description = styled.div`
  ${TEXT_BODY_2}
  width: 100%;
  color: #151515;
  white-space: pre-line;
  opacity: 0;
  animation: ${fadeIn} 0.6s linear;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;

  &:nth-of-type(2) {
    animation-delay: 1.2s;
  }

  &:nth-of-type(3) {
    animation-delay: 1.8s;
  }

  @media ${device.computer} {
    width: 210px;

    &:nth-of-type(1) {
      grid-column: 1 / 3;
      margin-left: 32px;
    }

    &:nth-of-type(2) {
      grid-column: 4 / 6;
    }

    &:nth-of-type(3) {
      grid-column: 7 / 9;
    }
  }

  @media ${device.desktop} {
    width: 332px;

    &:nth-of-type(1) {
      grid-column: 1 / 3;
    }

    &:nth-of-type(2) {
      grid-column: 4 / 6;
    }

    &:nth-of-type(3) {
      grid-column: 7 / 9;
    }
  }
`;

export const VerticalDivider = styled.hr`
  display: block;
  width: 1px;
  min-width: 1px;
  height: calc(70% + 4vh);
  min-height: 10vh;
  margin: 0 32px;
  background: #eaeaea;
  opacity: 0;
  animation: ${fadeIn} 0.6s linear;
  animation-delay: 0.9s;
  animation-fill-mode: forwards;

  &:nth-of-type(2) {
    animation-delay: 1.5s;
  }

  @media ${device.computer} {
    height: 340px;
    margin: 10px 0 0 0;
    justify-self: end;

    &:nth-of-type(1) {
      grid-column: 3;
    }

    &:nth-of-type(2) {
      grid-column: 6;
    }
  }

  @media ${device.desktop} {
    height: 207px;
    justify-self: end;

    &:nth-of-type(1) {
      grid-column: 3;
    }

    &:nth-of-type(2) {
      grid-column: 6;
    }
  } ;
`;

export const HorizontalDivider = styled.hr`
  width: 100%;
  min-height: 1px;
  margin: 40px 48px 40px 0;
  background: #eaeaea;
  opacity: 0;
  animation: ${fadeIn} 0.6s linear;
  animation-delay: 0.9s;
  animation-fill-mode: forwards;

  &:nth-of-type(2) {
    animation-delay: 1.5s;
  }
`;

export const Wrapper = styled.div`
  margin-top: 40px;
  max-width: 90%;
`;
